.footer {
  background-color: #000;
  color: #d3d3d3; /* Light grey color for the text */
  font-family: 'Marcellus', serif;
}

.footer-map img{
  width: 100%; /* Ensures the image takes up the full width of its container */
  height: 300px; /* Set your desired height */
  object-fit: cover; /* Allows the image to cover the entire container */
  object-position: center; /* Keeps the center of the image in focus */
}
.footer-top {
  position: relative;
  height: 300px;
}
.footer-top .bg-image{
  background-image: url('../Images/FooterImage.jpeg');
  background-size: cover;
  background-position: center;
  padding: 40px 20px;
  height: 100%;
  filter: brightness(50%);
}

.footer-top .footer-title{
  text-align: center;
  position: absolute;
  width: 100%;
  top: 10%
}
.footer-top .footer-timing {
  position: absolute;
  width: 100%;
  top: 60%;
}
.footer-top .footer-hours{
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align all text to the top */
  margin: 0 auto;
  max-width: 1000px;
}

.footer-hours-section {
  flex: 1;
  padding: 20px;
}

.footer-hours-section.center {
  flex: 1;
  border-left: none;
  border-right: none;
  padding: 20px;
  text-align: center;
}

.footer-hours-section h4 {
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: #EAEAEA;
  font-family: Plus Jakarta Sans;
}



.footer-title {
  font-size: 2rem;
  font-family: Marcellus;
  margin: 5px 0;
  color: #ffffff; /* Bright white for main title */
}

.footer-hours-section p {
  font-size: 1rem;
  margin-top: 5px;
  font-family: Plus Jakarta Sans;
}



.footer-logo {
  width: 70px;
  margin-bottom: 10px;

}


.footer-location,
.footer-contact,
.footer-newsletter {
  flex: 1;
  padding: 20px;
  text-align: left; /* Align text to the left for consistency */
}

.footer-location h4,
.footer-contact h4,
.footer-newsletter h4 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #FFFFFF;
}

.footer-location p,
.footer-contact p,
.footer-newsletter p {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #939393;
  font-family: Plus Jakarta Sans;
}

.directions-button,
.submit-button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.directions-button:hover,
.submit-button:hover {
  background-color: #ddd;
}

.newsletter-input-group {
  position: relative;
}

.newsletter-input-group input {
  padding: 5px;
  border: 1px solid #545454;
  background-color: transparent;
  color: #fff;
  margin-right: 10px;
  width: 100%;
  border-radius: 3px;
  padding-left: 10px;
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal content */
.modal-content {
  background: black; /* Black background for better contrast */
  color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 90%; /* Ensure it fits on smaller screens */
  width: 300px; /* Set a base width */
}

/* Modal button */
.modal-content button {
  background-color: black; /* Black background */
    color: white; /* White text */
    border: 2px solid white; /* White border */
    padding: 10px 20px;
    margin-top: 20px; 
    /* font-size: 14px; */
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.modal-content button:hover {
  background-color: #333; /* Slightly lighter black on hover */
  color: white; /* Keep text white on hover */
}


.newsletter-input-group button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  border-width: 0;
  border-radius: 3px;
  font-size: 11px;
  cursor: pointer;
}

.newsletter-input-group button:hover {
  background-color: #ddd;
}

.newsletter-input-group button:disabled {
  background-color: #fff; 
  color: #000; 
  cursor: not-allowed; 
}

.footer-bottom-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-top: 1px solid #333;
  font-size: 0.9rem;
}

.footer-social a {
  color: #fff;
  font-size: 1.2rem;
  margin-left: 10px;
}

.footer-social a:hover {
  color: #ddd;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.footer-copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
}

.footer-copyright p {
  margin: 0;
  font-family: 'Marcellus', serif;
  color: #FFFFFF;
}

.footer-copyright a {
  text-decoration: none;
  color: inherit;
}


.social-media-icons {
  display: flex;
}

.social-media-icons a {
  margin-left: 15px;
}

.social-icon {
  width: 14px;
  height: 14px;
  transition: opacity 0.3s ease;
}

.social-icon:hover {
  opacity: 0.7;
}

.footer-separator {
  border-top: 1px solid #333;
  margin: 0 0;
}