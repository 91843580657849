@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

/* ============================================================================================================================ */
.hero-section {
  width: 100%;
  font-family: 'Marcellus', serif;
  box-sizing: border-box;
  padding-top: 58px; 
}

.hero-block {
  background-image: url('../Images/MainImage.jpeg');
  background-size: cover;
  background-position: center;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  /* filter: brightness(50%); */
}


.head-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* color: #FFFFFF; */
  text-align: center;
  font-size: 48px;
  font-weight: 400;
  border-radius: 8px;
  font-family: 'Marcellus', serif;
}

/* ============================================================================================================================ */

.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 80px 20px;
  background-color: #1a1a1a;
  color: #fff;
  gap: 10%; /* Set the gap between image and content */
}

.text-content {
  flex: 1;
  max-width: 45%; /* Content takes 45% of the width */
  padding: 20px;
  text-align: left;
}

.gallops-bar-head {
  margin-bottom: 20px;
  font-size: 56px;
  font-weight: 400;
  font-family: 'Marcellus', serif;
  color: #FFFFFF;
}

.gallops-bar-para {
  color: #939393;
  font-size: 20px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
}

.view-more {
  color: #0C0C0C;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  padding: 10px 20px;
  border: 2px solid white;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.view-more:hover {
  background-color: #333;
  color: white;
}

.image-content{
  display: flex;
  gap: 25px;
  padding-right: 20px;
}

.image-content-2 {
  padding-top: 40px;
}

.image {
  border-radius: 20px;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.image1, .image2 {
  height: 430px; /* Adjust height for portrait orientation */
  width: 312px;
}

@media (max-width: 768px) {
  .section {
    flex-direction: column;
    text-align: center;
    padding-top: 2rem;
  }

  .text-content, .image-content {
    max-width: 100%;
  }

  .image-content {
    flex-direction: column;
  }

  .image1, .image2 {
    height: auto;
  }
  .head-content {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .image {
    max-width: 100%;
  }
}


/* ============================================================================================================================= */
.outer-grid {
  padding: 20px;
  /*background-color: #1a1a1a;*/
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns with equal width */
  gap: 20px; /* Space between the grid items */
}

/* Wrapper for each image */
.image-wrapper {
  position: relative; /* Create a positioning context for the pseudo-element */
  width: 100%; /* Full width of the grid cell */
  /*padding-top: 100%; /* Aspect ratio (1:1) for square blocks */
  overflow: hidden; /* Hide overflow to ensure images fit into the square */
  border-radius: 10px; /* Rounded corners */
  height: 200px;
}

/* Style for the images */
.image-wrapper img {
  position: absolute; /* Position image absolutely within wrapper */
  top: 0;
  left: 0;
  width: 100%; /* Ensure the image fills the container */
  height: 100%; /* Ensure the image fills the container */
  object-fit: cover; /* Ensure the image covers the square while maintaining aspect ratio */
}

/* Responsive design */
@media (max-width: 768px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
  }
}

@media (max-width: 480px) {
  .image-grid {
    grid-template-columns: 1fr; /* 1 column on very small screens */
  }
}


/* ============================================================================================================================= */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  background-color: #1a1a1a;

}

.image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  max-width: 600px;
}

.text-container-para1 {
  color: #EAEAEA;
  font-size: 20px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 400;
}

.text-container-para2 {
  color: #939393;
  margin: 10px 0;
  font-size: 20px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
}

.text-container button, .menu-foot button {
  color: #0C0C0C;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  padding: 10px 20px;
  border: 2px solid white;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.text-container button:hover {
  background-color: #333;
  color: white;
}

.menu-foot button:hover {
  background-color: #333;
  color: white;
}

@media (min-width: 768px) {
  .container {
    flex-direction: row;
    justify-content: space-between;
    gap: 25px;
  }

  .image-container, .text-container {
    flex: 1;
    margin: 0;
  }

  .text-container {
    text-align: left;
    max-width: 47%; /* Content takes 45% of the width */
    border-left: 1px solid #545454;
    padding:40px 25px;
  }
}

.menu-grid {
  padding: 3rem;
  row-gap: 20px;
  background: transparent url("../Images/bg_authentic-creole-and-appetizers.jpeg");
}
.menu-row{
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}
.menu-head{
  font-size: 48px;
  min-width: 50%;
  vertical-align: middle;
}

.menu-grid .image-grid {
  display: flex;
  justify-content: space-between;
}

.image-right {
  display: flex;
  gap: 20px;
  width: 50%;
}

.image-right .menu-image{
  width: 50%;
}

.menu-image{
  position: relative;
  border-radius: 24px;
}

.menu-grid img{
  width: 100%;
  height: 100%;
  border-radius: 24px;
}

.image-grid .menu-image{
  width: 100%;
}

.menu-grid .menu-name{
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 24px;
  max-width: 170px;
}

.menu-grid .menu-link{
  position: absolute;
  left: 15px;
  top: 80px;
  font-size: 18px;
  max-width: 170px;
}



/* ========================================================================================================================== */
/* Reservations Container */

.reservations-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
  background-color: #1a1a1a;
}

.reservations-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.reservations-image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
}

.reservations-content {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 600px;
  margin-left: 0;
  width: 100%;
}

.reservations-header {
  color: #FFFFFF;
  margin-bottom: 10px;
  font-size: 48px;
  font-weight: 400;
  border-radius: 8px;
}


.reservations-para {
  color: #939393;
  margin: 10px 0;
  font-size: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
}

.reservations-content .form-group {
  margin-bottom: 1rem;
  width: 100%;
}


.form-group select.people {
  background-color: white;
  border: 1px solid black;
  color: black;
  padding-left: 40px;
  padding-right: 40px;
  height: 50px;
  width: 100%;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  background-color: white;
  background-image: url('../Images/people.svg'), url('../Images/dropdown.svg'); /* Both images */
  background-repeat: no-repeat, no-repeat; /* Do not repeat the images */
  background-position: 10px center, calc(100% - 10px) center; /* Position icons */
  background-size: 23px 23px, 24px 24px; /* Size of the icons */
  appearance: none; /* Remove default dropdown arrow */
  border-radius: 0.5rem;
  filter: invert(100%);
 
}
.form-group input[type="datetext"] {
  background-color: white;
  border: 1px solid black;
  color: black;
  height: 50px;
  width: 100%;
  border-radius: 0.5rem;
  background-image: url('../Images/calendar.svg');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 23px 23px;
  padding-left: 40px;
  padding-right: 40px;
  filter: invert(100%);
}

.form-group input[type="datetext"]::placeholder {
  color: rgb(12, 1, 1); /* Placeholder text color */
  opacity: 1; /* Ensure placeholder is fully visible */
  font-size: 16px;
}

.form-group input[type="time"] {
  background-color: white;
  border: 1px solid black;
  color: black;
  height: 50px;
  width: 100%;
  border-radius: 0.5rem;
  background-image: url('../Images/clock.svg');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 23px 23px;
  filter: invert(100%);
}

/* Form group styling */
.form-group {
  margin-bottom: 15px;
  position: relative;
}

/* For webkit browsers like Chrome and Safari */
input[type="time"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  position: absolute;
}

/* For Firefox */
input[type="time"]::-moz-focus-inner {
  border: 0;
}

input[type="time"]::-moz-focus-outer {
  border: 0;
}

input[type="time"]::-moz-placeholder {
  color: transparent;
}

.form-group select,
.form-group input[type="date"],
.form-group input[type="time"] {
  width: 100%;
  padding: 10px 15px 10px 45px; /* Adjust padding to make room for the icons */
  border: 1px solid black;
  border-radius: 5px;
  box-sizing: border-box;
  border-radius: 0.5rem;
  font-size: 16px;
}
/* */

.reservations-content label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
  font-size: 16px;
  width: 100%;  
}

.reservations-content select,
.reservations-content input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #333;
  color: white;
}

.reservations-content select::placeholder,
.reservations-content input::placeholder {
  color: white;
}

.reservations-content button {
  color: #0C0C0C;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  padding: 10px 20px;
  border: 2px solid white;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.reservations-content button:hover {
  background-color: #333;
  color: white;
}

@media (min-width: 768px) {
  .reservations-container {
    flex-direction: row;
    justify-content: flex-start;
    gap: 25px;
  }

  .reservations-image-container, .reservations-content {
    flex: 1;
    margin: 0;
  }

  .reservations-content {
    max-width: 47%;
    border-left: 1px solid #545454;
    padding:0 25px;
  }
}

@media (max-width: 768px) {
  .menu-row, .image-right {
    flex-direction: column;
  }
  .menu-head, .image-right, .image-right .menu-image {
    width: 100%;
  }
}
