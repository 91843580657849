.menu {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.menu-section, .daily-menu{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-section.reverse {
  flex-direction: row-reverse;
}

.menu-text, .daily-text {
  flex: 1;
  padding: 20px;
}
.menu-text, .daily-text {
  border-right: 1px solid #545454;
}
.reverse .menu-text {
  border-left: 1px solid #545454;
  border-right: 0;
}



.menu-text h2, .daily-text h2 {
  font-size: 2rem;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.daily-text h2 {
  margin-left: 1rem;
}

.menu-text ul {
  list-style: none;
  padding: 0;
}

.daily-text ul {
  margin-left: 1rem;
  list-style-type:disc;
}
.menu-text li, .daily-text li {
  margin-bottom: 20px;
}

.daily-text li {
  width: 50%;
  display: inline-block;
}

.menu-text h4, .daily-text h4 {
  font-size: 1.5rem;
  color: #EAEAEA;
  margin: 0;
}

.menu-text p, .daily-text p {
  font-size: 1rem;
  color: #B0B0B0;
  margin: 5px 0;
}

.misc{
  text-transform: uppercase;
  text-decoration: underline;
  color: #FFF !important;
}

.menu-text .price {
  font-size: 1rem;
  color: #FFFFFF;
}

.menu-images, .dail-image {
  flex: 1;
  text-align: center;
}

.menu-images img {
  width: 80%;
  border-radius: 8px;
}

.menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.menu-item h4 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  flex: 1;
}

.menu-item p {
  margin: 0;
  font-size: 0.9rem;
  flex: 2;
  line-height: 1.5;
}
.centeredText {
text-align: center;
font-size: 56px;
text-transform: uppercase;
margin-top: 2rem;
}

.centeredText.small, .centeredText .small {
text-align: center;
font-size: 16px;
text-transform:none;
margin-top: 0 !important;
color: #939393;
padding: 0 3rem;
}

.centeredText p{
display: inline-block;
max-width: 700px;
}
.centeredText.no-margin span, .centeredText.single span{
display: block;
}

.centeredText.single {
  font-size: 26px;
  text-transform:capitalize;
}

.centeredText.single p{
  max-width: 100%;
}

.top-image-section {
  width: 100%; 
    height: 500px; 
    overflow: hidden; 
    position: relative; 
    margin: 0 auto; 
    object-fit:cover;
    object-position: center;
}

.top-image-section img {
  display: block; 
  object-fit: cover;
  width: 100%; 
  height: 100%;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-size: 24px;
  padding: 20px;
  border-radius: 8px;
  font-family: 'Marcellus', serif;
}


.text-overlay p{
  filter: brightness(70%);
}

.tapRoomDiv {
  display: flex;
  padding-bottom: 2rem;
}

.tapColumn {
  width: 50%;
  margin-left: 9%;
}

.tapColumn div {
  padding-left: 1rem;
  border-left: 1px solid #545454;
  margin-bottom: 2rem;
}

.tapColumn h4 {
  padding: 0 0 1rem;
}

.tapColumn ul{
  margin-left: 2rem;
}

@media (max-width: 768px) {
  .menu-section, .menu-section.reverse, .footer-bottom, .footer-hours {
    flex-direction: column;
  }
  .footer-hours{
    align-items: center;
    display: block !important;
  }
  .footer-top {
    height: 550px;
  }
  .footer-timing {
    top: 40%;
  }
  .footer-bottom {
    align-items: flex-start;
  }
  .daily-menu {
    flex-direction: column;
  }
  .daily-text {
    width: 100%;
    border: 0;
  }
  .daily-text li{
    display: block;
    width: 100%;
  }
  .daily-image {
    padding: 0 2rem;
  }
  .daily-image img{
    width: 100% !important;
  }
  .centeredText {
    font-size: 46px;
  }
  .centeredText p {
    width: 100%;
    padding: 0 1rem;
  }
  .menu-text{
    border: 0 !important;
    padding: 0 2rem;
  }
  .tapRoomDiv {
    flex-direction: column;
  }
}

