.navbar {
    position: fixed;
    top: 9px;
    left: 0;
    width: 100%;
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    padding: 0 20px; /* Adjust padding for proper spacing */
    box-sizing: border-box; /* Ensures padding is included in the width */
    opacity: 1;
    z-index: 1000;
    position: fixed; /* Fixed position to stay on top */
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure it stays above other content */


  }

  .navbar-logo {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  
  .logo-image {
    height: 58px; /* Adjust the height based on your logo */
    width: auto;
  }
  .navbar-menu .active {
    color: white;
    text-decoration: none;
  }
  
  .navbar-menu .active {
    color: #FFD700; /* Replace with your desired highlight color */
  }
  
 
  .navbar-menu {
   
    position: absolute;
    top: 60px;
    right: 20px;
    display: none;
    flex-direction: column; /* Ensure this remains */
    align-items: flex-start; /* Align items to the left within the dropdown */
    background-color: black;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }

  .navbar-menu.open {
    display: flex; /* For mobile view */
  }
  
  .navbar-menu a {
    display: block; /* Ensure each link is a block-level element */
    width: 100%; /* Ensure links take the full width of the dropdown */
    color: #fff;
    text-decoration: none;
    padding: 10px 15px;
    margin: 0 10px;
    border-radius: 3px;
    white-space: nowrap;
  }

  .navbar-menu a:hover {
  color: #f0a500; /* Optional: Change text color on hover */
  }
  

  
  .reserve-button {
    background-color: black; /* Black background */
    color: white; /* White text */
    border: 2px solid white; /* White border */
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .reserve-button:hover {
    background-color: #333; /* Slightly lighter black on hover */
    color: white; /* Keep text white on hover */
  }

@media (max-width: 768px) {
    .navbar-menu {
      width: 100%; /* Optional: Take full width on mobile */
    right: 0;

    }

    .navbar-menu.open {
      display: block;
    }
    
    .navbar-toggler {
      color: rgba(255, 255, 255, .55);
      border: 0;
      background-color: black;
    }
    .nav-buttons{
      display: flex;
      gap: 20px;
    }

    .navbar-menu.open a:hover {
      background-color: grey; /* Grey background on hover */
    color: #f0a500; /* Optional: Change text color on hover */
    }
  }

  @media (min-width: 769px) {
    .navbar-menu {
    display: flex !important; /* Force flex display on larger screens */
    position: static; /* Reset positioning */
    flex-direction: row; /* Horizontal layout */
    align-items: center; /* Align items vertically in the navbar */
    background-color: transparent; /* Remove background color */
    box-shadow: none; /* Remove shadow */
    padding: 0; /* Remove extra padding */
    margin: 0; /* Reset margin */
  }

  .navbar-menu a {
    margin: 0 10px; /* Add spacing between menu items */
    padding: 0; /* Remove padding */
    color: #fff; /* Ensure links are visible */
  }

  .navbar-toggler {
    display: none; /* Hide the toggle button */
  }
  }
  
