body {
    margin: 0;
    font-family: 'Marcellus', serif;
    background-color: #000; /* Set the overall background to black */
    color: #fff;
  }
  
  .careers-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;     /* Center vertically if needed */
    flex-direction: column;  /* Stack child elements vertically */
    width: 100%;
    box-sizing: border-box;
    padding-top: 58px; 
    min-height: 100vh; /* Ensure container takes up full viewport height */
  }
  
  .top-image-section {
    width: 100%;
    max-height: 500px; /* Set a maximum height for the image section */
    overflow: hidden;
  }
  
  .error-message {
    color: red;
    font-size: 13px;
    margin-top: 4px;
  }
  

  .top-image-section img {
    width: 100%;
    height: auto;
    object-fit: cover;
    filter:brightness(50%);
  }
  
  .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    font-size: 24px;
    padding: 20px;
    border-radius: 8px;
    font-family: 'Marcellus', serif;
  }
  
  
  .text-overlay p{
    filter: brightness(70%);
    font-size: 18px;
  }
  .full-width-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  
  .text-overlay h1 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
 
  
  .career-form {
    background-color: #121212;
    padding: 30px;
    border-radius: 10px;
    max-width: 900px;
    width: 100%;
    margin-top: 40px; /* Add space between the image and the form */
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); /* Adds a subtle shadow */
    text-align: center;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    flex: 1;
    margin-right: 10px; /* Add space between columns */
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #555;
    border-radius: 4px;
    background-color: #222;
    color: #fff;
    width : 100%;
  }

  .form-group.full-width {
    flex-basis: 100%;
  }
  
  .form-group.half-width {
    flex-basis: 48%;
  }
  
  .form-group.third-width {
    flex-basis: 31%;
  }
  
  
  .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
  }
  
  .checkbox-group label {
    display: flex;
    align-items: center;
    font-size: 14px;
    white-space: nowrap; 
    margin-bottom: 0; 
  }

  .checkbox-group input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .form-group input[type="file"] {
    background-color: #222;
    color: #fff;
  }
  
  .submit-button {
    padding: 15px;
    background-color: black;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    width: auto;
    margin-top: 20px;
    border: 2px solid white; /* White border */
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: block;
  }
  
  .submit-button:hover {
    background-color: #333; /* Slightly lighter black on hover */
    color: white; /* Keep text white on hover */
}

.form-group:last-child {
  margin-right: 0; /* Remove right margin for the last item */
}

@media (max-width: 768px) {
  .text-overlay {
    width: 100% !important;
  }
  .career-form {
    margin: 2rem 1rem;
    width: auto;
  }
  .form-row {
    display: block;
  }
  .checkbox-group{
    column-gap: 20px;
    row-gap: 5px;
  }
}
  
