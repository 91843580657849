body {
  margin: 0;
  font-family: 'Marcellus', serif;
  background-color: #000; /* Set the overall background to black */
  color: #fff;
}





.event-request-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically if needed */
  flex-direction: column;  /* Stack child elements vertically */
  width: 100%;
  box-sizing: border-box;
  padding-top: 58px; 
  min-height: 100vh; /* Ensure container takes up full viewport height */
}


.eventDate input[type="date"] {

  padding: 12px;
    border-radius: 5px;
    border: 1px solid #555;
    background-color: #222;
    color: #fff;
    font-size: 14px;
    width: 100%;
    margin: 0 0 20px;
    box-sizing: border-box;

}

.form-group-eventDate {
margin-right: 10px;
margin-top: 20px;
}

.error-message {
  color: red;
  font-size: 13px;
  margin-top: 4px;
}

.top-image-section {
  width: 100%; 
    height: 500px; 
    max-height: 800px; 
    overflow: hidden; 
    position: relative; 
    margin: 0 auto; 
    object-fit: cover;
    object-position: center;
}

.top-image-section img {
  display: block; 
  width: 100%; 
  height: 100%; 
  max-height: 100%;
  object-fit: cover; 
  object-position: center;
  filter: brightness(50%);
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-size: 24px;
  padding: 20px;
  border-radius: 8px;
  font-family: 'Marcellus', serif;
}


.text-overlay p{
  filter: brightness(70%);
}

.event-request-form {
  background-color: #121212;
  padding: 30px;
  border-radius: 10px;
  max-width: 900px;
  width: 100%;
  margin-top: 40px; /* Add space between the image and the form */
  margin-left: auto; /* Center horizontally */
  margin-right: auto; /* Center horizontally */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); /* Adds a subtle shadow */
  text-align: center;
}

.form-group input[type="date"]{
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 12px;
  border: 1px solid #555;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: #222;
}

input[type="date"]::-webkit-calendar-picker-indicator{
  opacity: 0;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}


.form-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #fff;
  text-align: left;
}

.event-form-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.event-form-group {
  display: flex;
  flex-direction: column;
  width: 48%;
  text-align: left; 
}

.form-group label {
  font-size: 14px;
  color: #fff;
  margin-bottom: 5px;
  text-align: left;
}


/* Styling for the separator line */
.separator-line {
  border: 0;
  height: 1px;
  background: #555;  /* Color of the line */
  margin: 20px 0;    /* Spacing above and below the line */
  width: 100%;       /* Ensure it spans the full width of the form */
}



.form-group input,
.form-group textarea {
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #555;
  background-color: #222;
  color: #fff;
  font-size: 14px;
  width: 100%;
}

textarea {
  resize: none;
  height: 80px;
}

.submit-button {
  padding: 15px;
  background-color: black;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  width: auto;
  margin-top: 20px;
  border: 2px solid white; /* White border */
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: block;
}

.submit-button:hover {
  background-color: #333; /* Slightly lighter black on hover */
    color: white; /* Keep text white on hover */
}

@media (max-width: 768px) {
  .event-request-form {
    margin: 2rem 1rem;
    width: auto;
  }
  .event-form-row {
    display: block;
  }
}
